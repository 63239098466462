import * as types from '../../constants/ActionTypes'
import App from '../../common.js' // 引入调用接口公共方法

const commonData = {
    configList: [], //全局权限配置-(管理后台学员端展示配置)
    currPageConfigList: []
}

const configInfoData = (state = commonData, action) => {
    switch (action.type) { 
        case types.CONFIG_LIST:
            return {...state, configList: action.data}
        case types.CURR_PAGE_CONFIG_LIST:
            return {...state, currPageConfigList: action.data}
        default:
            return {...state}
    }
}

export const getConfigList = state => {
    return state.configList
}
export const getCurrPageConfigList = state => {
    return state.currPageConfigList
}

export default configInfoData