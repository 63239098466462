import React from 'react';
import ReactDOM from 'react-dom';
import 'babel-polyfill';
import './index.scss';
import Page from './Page';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import 'moment/locale/zh-cn';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux'
import reducer from './reducer'
import * as serviceWorker from './serviceWorker';
import { LocaleProvider } from 'antd';
import tool from './utils/commonFuc'
import Session from './utils/Session'
// import { createLogger } from 'redux-logger'
// import StudyHome from './views/Study/StudyHomePage'
// import StudyCourseItem from './views/Study/Views/StudyCourseItem';
// import CourseBrief from './views/Study/CourseBrief'

const middleware = [thunk];
// if (process.env.NODE_ENV !== 'production') {
//     middleware.push(createLogger());
// }
const store = createStore(reducer, applyMiddleware(...middleware));

// 挂载全局方法和对象
React.Component.prototype.$tool = tool;
React.Component.prototype.$Session = Session;

// 临时，不展示部分数据给领导看...
let timeNotShowFlag  = localStorage.getItem("timeNotShowFlag");
if (typeof (timeNotShowFlag) != 'undefined') {
    React.$timeNotShowFlag=timeNotShowFlag==0?false:true;
}else{
    React.$timeNotShowFlag=true;
}
console.log("init timeNotShowFlag:",React.$timeNotShowFlag);

/* eslint-disable */
Object.prototype.equals = function(object) {
    if(!object){
        return false
    }
    
    let aProps = Object.getOwnPropertyNames(this);
    let bProps = Object.getOwnPropertyNames(object);
    if (aProps.length !== bProps.length) {
        return false;
    }
    for (let i = 0; i < aProps.length; i++) {
        let propName = aProps[i];
        if (this[propName] !== object[propName]) {
            return false;
        }
    }
    return true;
}
Object.defineProperty(Object.prototype, "equals", {enumerable: false});

Array.prototype.equals = function (array) {
    if (!array)
        return false;
    
    if (this.length != array.length)
        return false;

    for (var i = 0, l = this.length; i < l; i++) {
        if (this[i] instanceof Array && array[i] instanceof Array) {
            if (!this[i].equals(array[i]))
                return false;       
        }           
        else if (this[i] != array[i]) { 
            return false;   
        }           
    }       
    return true;
}
Object.defineProperty(Array.prototype, "equals", {enumerable: false});
//拦截，是手机打开就跳转到手机页面
if(navigator.platform){
    let system ={  
        win : false,  
        mac : false,  
        xll : false 
    };
    system.win = navigator.platform.indexOf("Win") == 0;  
    system.mac = navigator.platform.indexOf("Mac") == 0;  
    system.x11 = (navigator.platform == "X11") || (navigator.platform.indexOf("Linux") == 0);  
 
    if(system.win||system.mac||system.xll){
        console.log("您电脑的系统是"+navigator.platform)
    }else{
        // window.location.href = 'http://172.16.100.150/studymobile/#/home'        
        // window.location.href = 'https://studymobile.mshiedu.com' // todo 手机打开跳手机页面
        window.location.href = location.protocol + '//' + location.host + '/uniapp'
    }
}

const test = (arr1,arr2) =>{
    let startIndex1 = 0;
    let startIndex2 = 0;
    let endIndex1 = arr1.length-1;
    let endIndex2 = arr2.length-1;
    let arr = [];
    while(startIndex1<=endIndex1&&startIndex2<=endIndex2){
        if(arr1[startIndex1] > arr2[startIndex2]){
            arr.push(arr2[startIndex2]);
            startIndex2++;
        } else if(arr1[startIndex1] < arr2[startIndex2]){
            arr.push(arr1[startIndex1]);
            startIndex1++;
        }else{
            arr.push(arr2[startIndex2]);
            arr.push(arr1[startIndex1]);
            startIndex2++;
            startIndex1++;
        }
    }
    if(startIndex1 < endIndex1){
        arr = arr.concat(arr1.slice(startIndex1));
    }
    if(startIndex2 < endIndex2){
        arr = arr.concat(arr2.slice(startIndex2));
    }
    return arr 
}
async function async1() {
    console.log('async1 start');  //  2
    await async2();
    console.log('async1 end');  //  6
}
async function async2() {
    console.log('async2');   //   3
}
console.log('script start');  //  1
setTimeout(function() {
    console.log('setTimeout'); //8
}, 0)
async1();
new Promise(function(resolve) {
    console.log('promise1'); //   4
    resolve();
}).then(function() {
    console.log('promise2');  //7
});
console.log('script end'); //5
document.title =  window.localStorage.getItem('pcName') || ''
let favicon = document.querySelector('link[rel="icon"]');
favicon = document.createElement("link");
favicon.rel = "icon";
favicon.id = "favicon_icon";
favicon.href = window.localStorage.getItem('pcWebsiteIconUrl');
document.head.appendChild(favicon);
document.getElementById('header_keywords').setAttribute('content', window.localStorage.getItem('pcName'))
document.getElementById('header_description').setAttribute('content', window.localStorage.getItem('pcName'))

ReactDOM.render(
<Provider store={store}>
    <LocaleProvider locale={zh_CN}>
        <Page />
    </LocaleProvider>
</Provider>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
