/**
 * Created by 彬 on 2023.2.02.
 */
import { combineReducers } from 'redux';
import studyInfo from './course/studyInfo';
import configInfo from './common/index';



export default combineReducers({
    studyInfo,
    configInfo
});
