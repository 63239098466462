export function isLogin() {
    return sessionStorage.getItem('token')?true:false;
}

let version = process.env.Text[0];
// console.log(process.env.Text[0]);

export default {
    version: version,      
    homeTrendList:[{//首页趋势分析下拉列表
        label:'流水',
        value:'FEE_AMOUNT'
    },{
        label:'订单数',
        value:'ORD_NUM'
    },{
        label:'学员数',
        value:'STUDY_NUM'
    },{
        label:'ARPU',
        value:'ARPU'
    },{
        label:'ROI',
        value:'ROI'
    },{
        label:'退费 ',
        value:'BACK_AMOUNT'
    },{
        label:'机会转化率',
        value:'CHANCE_RATE'
    },{
        label:'名片转化率',
        value:'CARD_RATE'
    },],
    trendTimeList:[{//趋势分析时间颗粒列表
        key:"DAY",
        name:'日',
        days:1,
    },{
        key:"WEEK",
        name:'周',
        days:7,
    },{
        key:"MONTH",
        name:'月',
        days:31,
    },{
        key:"YEAR",
        name:'年',
        days:365,
    },],
}