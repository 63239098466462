// eslint-disable-next-line no-alert
import React from 'react';
import axios from "axios";
import {
  message
} from "antd";
import tool from "./utils/commonFuc.js";

message.config({
  top: 150,
  duration: 2,
});
// message.error(data.msg);
const mockUrl ='http://172.16.100.151:3000/mock/115'  //YApi测试接口

//需要使用YApi测试接口的url
const mockApi = [
  // `/student/web/examReservation/list`,
  // `/student/web/examReservation/reservation`,
  // `/student/web/examReservation/absent`,
  // `/student/web/examReservation/livePersonInfo`,
  // `/student/web/examReservation/detail`
]

export default {
  axiosData: async (method, url, data, isShowToast = true, hostType = "") => {
    let token = await tool.getUserToken()
    let version = process.env.Text[0];
    let host = process.env.Text[1];
    if (mockApi.indexOf(url) !== -1) {
      // YApi测试接口
      host = mockUrl
    }
    // console.log(' === token version host ===', token, version, host)
    // console.log(' === host + url = ', host + url)
    axios.defaults.headers["authorization"] = token;
    axios.defaults.headers["version"] = version;
    axios.defaults.headers["hostname"] =
      tool.getQueryValue("hostname") || window.location.hostname;
    return new Promise((resolve, reject) => {
      axios({
          method,
          url: host + url,
          data: data,
        })
        .then((res) => {
          let data = res.data;
          if (
            data.status === 0 ||
            data.status === "0" ||
            data.status === 10 ||
            data.code === 200 ||
            !data.status
          ) {
            // 拦截登录接口添加 token 和 pcUserInfo
            if (url.indexOf("/student/web/identification/login") > -1) {
              if (data && data.data && data.data.token.length > 0) {
                window.token = data.data.token;
                localStorage.setItem("token", data.data.token);
                localStorage.setItem("timeNotShowFlag", data.data.timeNotShowFlag);
                localStorage.setItem("pcUserInfo", JSON.stringify(data.data));
                let clientType = 1
                if(!data.data.fosterSchemeId){
                  //考前产品
                  clientType = 3
                }
                sessionStorage.setItem("clientType", JSON.stringify(clientType));

                // 临时，不展示部分数据给领导看
                let timeNotShowFlag = data.data.timeNotShowFlag;

                if (typeof (timeNotShowFlag) != 'undefined') {
                  React.$timeNotShowFlag = !!timeNotShowFlag;
                }else{
                  React.$timeNotShowFlag = true;
                }
                console.log("login timeNotShowFlag:",React.$timeNotShowFlag);
              }
            }

            resolve(data);
          } else if (data.status === 1 && data.code == "SEN001") {
            //token失效，跳转到登录页面，清除用户信息
            localStorage.removeItem("token");
            localStorage.removeItem("identityCheckLater");
            localStorage.removeItem("pcUserInfo");
            let index = window.location.href.indexOf("#");
            let url = window.location.href.slice(0, index);
            console.log(url);
            window.location.href = url + "#/login";
            window.location.reload();
            resolve({
              code: -700
            });
            // resolve(data)
          } else if (data.status == 429) {
            if (isShowToast) {
              message.error("用户点击过快，请稍后再试~~");
            }
            resolve({
              code: -429
            });
          } else {
            if (isShowToast) {
              message.error(data.msg);
            }
            resolve(data);
          }
        })
        .catch((err) => {
          if (isShowToast) {
            message.error(err.msg);
          }
          resolve({
            code: -1000
          });
        });
    });
  },
  // promise处理的异步
  testTimeout: function (msg) {
    return new Promise(function (resolve, reject) {
      if (msg === 1) {
        setTimeout(function () {
          resolve(" sync succ ");
        }, 1000);
      } else {
        reject(" sync fail ");
      }
    });
  },
  decodeAes: function (value) {
    const CryptoJS = require("crypto-js");
    const aesKey = process.env.Text[2];
    let reStr = value;
    try {
      let str = value;
      let cipherText = CryptoJS.enc.Base64.parse(str);
      let decrypted = CryptoJS.AES.decrypt({
          ciphertext: cipherText,
        },
        CryptoJS.enc.Utf8.parse(aesKey), {
          iv: CryptoJS.enc.Utf8.parse("53a068f649ad3565"),
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      );
      reStr = decrypted.toString(CryptoJS.enc.Utf8);
    } catch (e) {}
    return reStr;
  },
  encryptAes: function(value){
    const CryptoJS = require("crypto-js");
    const key = CryptoJS.enc.Utf8.parse('105ef85de7406002') 
    const iv = CryptoJS.enc.Utf8.parse('53a068f649ad3565')
    let srcs = CryptoJS.enc.Utf8.parse(value)
    let encrypted = CryptoJS.AES.encrypt(srcs, key, { 
      iv: iv, 
      mode: CryptoJS.mode.CBC, 
      padding: CryptoJS.pad.Pkcs7 
    });
    console.log(encrypted.toString(),888);
    return encrypted.toString();
  }
};