import * as types from '../../constants/ActionTypes'

const initialState = {
    hasLiveing: false,
}

const studyInfoData = (state=initialState,action) => {
    switch (action.type) { 
        case types.HAS_LIVE_STATUS:
            return {...state, hasLiveing: action.data}
        default:
            return {...state}
    }
}

export const getHasLiveing = state => {
    return state.hasLiveing
}

export default studyInfoData