import React, { Component } from 'react';
import {} from 'antd'
import './index.scss'
import noFoundImg from '../../assets/img/404.png'
import gradeInfoNone from '../../assets/grade/grade_info_none.png'

class MyComponent extends Component {    

    goToLogin = () => {
        this.props.history.push({ pathname: "/login" });
    }

    render() {
        return (
            <div className="noFoundContent">
                <div className="noFoundBox">
                        <img src={gradeInfoNone} alt=""/>
                        <p>很抱歉，页面未配置产品类型及其权限~</p>
                        <p>请刷新试试或者联系管理员</p>
                        {/* <a onClick={this.goToLogin}>去登录页</a> */}
                </div>
            </div>
        )
    }
}
export default MyComponent;